angular.module('app')
    .directive('crfStatusAssignment', [ function () {
        return {
            restrict: "E",
            scope: {
                status_type: '@statusType'
            },
            transclude: true,
            template: '<span uib-tooltip="{{status_type}}" class="fa fa-fw" ng-class="assignment_icon_class"></span>',
            controller: [
                '$scope',
                function ($scope) {
                    var assignment_icon_classes = {
                        'Accepted' : 'fa-check-circle text-success text-success-print',
                        'Declined' : 'fa-times-circle text-danger text-danger-print',
                        'Unknown' : 'fa-question-circle text-warning text-warning-print'
                    }

                    $scope.assignment_icon_class = assignment_icon_classes[$scope.status_type];
                }
            ]
        }
    }]);